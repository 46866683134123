import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import arrayShuffle from "array-shuffle";
import {Button, CircularProgress, Divider, List, ListItem, TextField, Typography} from "@mui/material";
import {
    Help as HelpIcon,
    OndemandVideo as AdIcon,
    Pause as PauseIcon,
    Replay as RematchIcon,
    Save as SaveIcon,
    Send as SendChallengeIcon,
} from "@mui/icons-material";
import {constants as games, games as gameUtils, LeaderBoard} from "@atttomyx/react-games";
import {TabBar, TabPanel} from "@atttomyx/react-components";
import HelpDialog from "../../dialogs/helpDialog/helpDialog";
import * as cacheService from "../../services/cache";
import * as challengeService from "../../services/challenges";
import * as gameService from "../../services/game";
import {arrays, datetime, forms, objects, sorting, strings} from "@atttomyx/shared-utils";
import {confirm, mobile} from "@atttomyx/react-utils";
import {getStars, toScore} from "../../utils/stats";
import {sortByGuesses} from "../../utils/sorting";
import {redact} from "../../utils/redactionUtils";
import {ads} from "@atttomyx/shared-constants";
import {OBSERVER_GAME, REGEX_LETTERS, REQUEST_REVIEW_AFTER, STOP_SHOWING_HELP_AFTER} from "../../constants";
import "./words.css";

const noop = () => {};

const allEmpty = (num) => {
    const flags = [];

    for (let f = 0; f < num; f++) {
        flags.push("empty");
    }

    return flags;
};

const calculateFlags = (word, candidate) => {
    const flags = [];

    for (let i = 0; i < candidate.length; i++) {
        const char1 = word.charAt(i);
        const char2 = candidate.charAt(i);

        if (char1 === char2) {
            flags[i] = "correct";

        } else if (word.indexOf(char2) > -1) {
            flags[i] = "partial";

        } else {
            flags[i] = "wrong";
        }
    }

    const charToCount = {};
    const charToNumNotWrong = {};

    for (let i = 0; i < word.length; i++) {
        const char = word.charAt(i);
        const count = charToCount[char] || 0;

        charToCount[char] = count + 1;
    }

    for (let i = 0; i < candidate.length; i++) {
        const char = candidate.charAt(i);

        if (flags[i] !== "wrong") {
            const notWrong = charToNumNotWrong[char] || 0;

            charToNumNotWrong[char] = notWrong + 1;
        }
    }

    for (let i = candidate.length; i > -1; i--) {
        const char = candidate.charAt(i);
        const count = charToCount[char] || 0;
        const numNotWrong = charToNumNotWrong[char] || 0;

        if (numNotWrong > count
            && flags[i] === "partial") {

            flags[i] = "wrong";
            charToNumNotWrong[char] = numNotWrong - 1;
        }
    }

    return flags;
};

const lettersRepeat = (word) => {
    const letters = [];
    let ret = false;

    for (let i = 0; i < word.length; i++) {
        const char = word.charAt(i);

        if (!ret) {
            if (arrays.contains(letters, char)) {
                ret = true;

            } else {
                arrays.addTo(letters, char);
            }
        }
    }

    return ret;
};

const findVowels = (word) => {
    const vowels = [];

    for (let i = 0; i < word.length; i++) {
        const char = word.charAt(i);

        if (char === 'A' || char === 'E' || char === 'I' || char === 'O' || char === 'U' || char === 'Y') {
            if (!arrays.contains(vowels, char)) {
                arrays.addTo(vowels, char);
            }
        }
    }

    return vowels;
};

const getKnownLetters = (word, candidates, allFlags, includeVowels) => {
    let letters = includeVowels ? findVowels(word) : [];
    let guess = 0;

    allFlags.forEach(flags => {
        const candidate = candidates[guess++];

        letters = addToKnownLetters(letters, candidate, flags);
    });

    return letters;
};

const addToKnownLetters = (knownLetters, candidate, flags) => {
    const letters = arrays.addAll([], knownLetters);

    for (let i = 0; i < candidate.length; i++) {
        const flag = flags[i];

        if (flag === "correct" || flag === "partial") {
            const char = candidate.charAt(i);

            if (!arrays.contains(letters, char)) {
                arrays.addTo(letters, char);
            }
        }
    }

    return letters;
};

const getRevealedIndexes = (word, allFlags) => {
    let indexes = [];

    allFlags.forEach(flags => {
        indexes = addToRevealedIndexes(indexes, word, flags);
    });

    return indexes;
};

const addToRevealedIndexes = (revealedIndexes, candidate, flags) => {
    const indexes = arrays.addAll([], revealedIndexes);

    for (let i = 0; i < candidate.length; i++) {
        const flag = flags[i];

        if (flag === "correct" && !arrays.contains(indexes, i)) {
            arrays.addTo(indexes, i);
        }
    }

    return indexes;
};

class Words extends Component {

    constructor(props) {
        super(props);

        const challenge = this.props.challenge;
        const resultsByChallengeId = this.props.resultsByChallengeId;
        const gamesPlayed = this.props.gamesPlayed;
        const mine = challenge ? challenge.mine || resultsByChallengeId[challenge.id] : null;
        const completed = objects.notNullOrUndefined(mine);
        const challengeId = gameUtils.getChallengeId(challenge);
        const candidates = cacheService.getCachedMoves(challengeId);
        const hint = cacheService.getCachedHint(challengeId);
        const difficulty = this.getDifficulty();
        const word = this.getWord();
        const flags = [];
        let guess = 1;

        candidates.forEach(candidate => {
            flags[guess++] = calculateFlags(word, candidate);
        });

        if (completed) {
            const correct = mine.metrics.correct;
            guess = mine.metrics.guesses || guess;

            this.startFireworks(guess, correct);
        }

        this.state = {
            flags: flags,
            guess: guess,
            candidates: candidates,
            hint: hint,
            lettersRepeat: lettersRepeat(word),
            knownLetters: getKnownLetters(word, candidates, flags, difficulty === games.EASY).sort(sorting.alphabetize),
            revealedIndexes: getRevealedIndexes(word, flags),
            completed: completed,
            showHelp: gamesPlayed <= STOP_SHOWING_HELP_AFTER,
            saving: false,
            failedToSave: false,
            tab: challenge && completed ? 1 : 0,
        }
    }

    componentDidMount() {
        const component = this;

        mobile.addAdObserver(OBSERVER_GAME, (type, status) => {
            switch (status) {
                case ads.STATUS.reward:
                case ads.RESULT.reward:
                    component.revealHint(true);
                    break;
                case ads.STATUS.failedToLoad:
                case ads.STATUS.failedToShow:
                case ads.RESULT.failure:
                    component.revealHint(false);
                    break;
            }
        });
    }

    startFireworks(guesses, correct) {
        const fireworks = this.props.fireworks;
        const stars = getStars(guesses, correct);

        console.log(`${stars} stars`);
        fireworks.start(stars);
    }

    stopFireworks() {
        const fireworks = this.props.fireworks;

        fireworks.stop();
    }

    getDifficulty() {
        return this.props.game.difficulty;
    }

    getWord() {
        return this.props.game.board.word.toUpperCase();
    }

    getDefinitions() {
        return this.props.game.board.definitions || [];
    }

    getCandidate(num) {
        const candidates = this.state.candidates;

        return candidates[num - 1];
    }

    setCandidate(num, event) {
        const candidates = this.state.candidates;
        const candidate = event.target.value;

        if (candidate) {
            if (candidate.match(REGEX_LETTERS)) {
                candidates[num - 1] = candidate.toUpperCase();
            }

        } else {
            candidates[num - 1] = null;
        }

        this.setState({
            candidates: candidates,
        });
    }

    submitGuess() {
        const word = this.getWord();
        const knownLetters = this.state.knownLetters;
        const revealedIndexes = this.state.revealedIndexes;
        const allFlags = this.state.flags;
        const guess = this.state.guess;
        const candidate = this.getCandidate(guess);

        if (guess > 0 && guess <= 10
            && word && candidate && word.length === candidate.length) {

            const flags = calculateFlags(word, candidate);

            allFlags[guess] = flags;

            const next = guess + 1;
            const completed = next > 10 || candidate === word;

            if (completed) {
                const correct = candidate === word;

                this.completedGame(guess, correct);
                this.startFireworks(guess, correct);
            }

            const challenge = this.props.challenge;
            const candidates = this.state.candidates;

            cacheService.storeCachedMoves(gameUtils.getChallengeId(challenge), candidates);

            this.setState({
                guess: completed ? guess : next,
                completed: completed,
                candidate: null,
                flags: allFlags,
                knownLetters: addToKnownLetters(knownLetters, candidate, flags).sort(sorting.alphabetize),
                revealedIndexes: addToRevealedIndexes(revealedIndexes, candidate, flags),
            });
        }
    }

    resendResult() {
        const word = this.getWord();
        const guesses = this.state.guess - 1;
        const candidate = this.getCandidate(guesses);

        this.completedGame(guesses, candidate === word);
    }

    sendChallenge() {
        const word = this.getWord();
        const guesses = this.state.guess - 1;
        const candidate = this.getCandidate(guesses);
        const correct = candidate === word;

        this.props.onSendChallenge({
            guesses: guesses,
            correct: correct,
        });
    }

    completedGame(guesses, correct) {
        const best = this.props.best;
        const challenge = this.props.challenge;
        const difficulty = this.getDifficulty();
        const success = () => this.resultSaved(guesses, correct);
        const failure = this.resultFailedToSave.bind(this);

        this.setState({
            saving: true,
            failedToSave: false,
        });

        if (guesses < best) {
            this.props.snackbar.setInfo("New personal best!");
            this.props.onPersonalBest(guesses);
        }

        if (challenge) {
            challengeService.completeChallenge(challenge.id, guesses, correct, success, failure);

        } else {
            gameService.completeGame(difficulty, guesses, correct, success, failure);
        }
    }

    resultSaved(guesses, correct) {
        const challenge = this.props.challenge;
        const gamesPlayed = this.props.gamesPlayed;

        this.setState({
            saving: false,
        });

        if (challenge) {
            const user = this.props.user;

            const result = {
                user: user,
                metrics: {
                    guesses: guesses,
                    correct: correct,
                },
                created: datetime.now(),
            };

            this.props.onResultSaved(challenge.id, result);

        } else {
            cacheService.clearCachedGame();
        }

        if (gamesPlayed >= REQUEST_REVIEW_AFTER) {
            mobile.popReview();
        }
    }

    resultFailedToSave(err) {
        this.props.snackbar.setError("Bad network connection");
        console.log(err);

        this.setState({
            saving: false,
            failedToSave: true,
        });
    }

    setShowHelp(showHelp) {
        this.setState({
            showHelp: showHelp,
        });
    }

    revealHint(playedAd) {
        const component = this;
        const snackbar = this.props.snackbar;
        const challenge = this.props.challenge;
        const board = this.props.game.board;
        const challengeId = gameUtils.getChallengeId(challenge);

        const hints = [];

        console.log(board);
        arrays.addAll(hints, board.sentences);

        const shuffled = arrayShuffle(hints);
        const random = shuffled[0];

        cacheService.storeCachedHint(challengeId, random);

        component.setState({
            hint: random,
        }, () => {
            if (playedAd) {
                snackbar.setSuccess("Hint revealed!");

            } else {
                snackbar.setWarning("There were no ads to show. Lucky you!");
            }
        });
    }

    renderWord() {
        const word = this.getWord();
        const completed = this.state.completed;
        const revealedIndexes = this.state.revealedIndexes;
        const chars = new Array(...word);
        let c = 0;

        return <table className="answer" cellPadding="0" cellSpacing="2">
            <tbody>
            <tr>
                {chars.map(char => <td key={"char_" + c++}>
                    <Typography className="outlined">
                        {completed || arrays.contains(revealedIndexes, c) ? char : "\u00a0"}
                    </Typography>
                </td>)}
            </tr>
            </tbody>
        </table>
    }

    renderGuess(num) {
        const word = this.getWord();
        const completed = this.state.completed;
        const guess = this.state.guess;
        const flags = this.state.flags[num] || allEmpty(word.length);
        const current = this.getCandidate(guess);
        const candidate = this.getCandidate(num);
        let f = 0;

        return !completed || candidate ?
            <table key={"guess_" + num} className="guess" cellPadding="0" cellSpacing="2">
                <tbody>
                <tr>
                    <td width="25%">
                        {num === guess && current && current.length > 0 && !completed
                            ? this.renderGuessButton()
                            : <Typography>{num}.</Typography>}
                    </td>
                    <td width="50%">
                        <TextField type="text" hiddenLabel className={num === guess && !completed ? "active" : null}
                                   value={forms.sanitizeValue(candidate)}
                                   disabled={completed || num !== guess}
                                   onChange={event => this.setCandidate(num, event)}
                                   inputProps={{
                                       maxLength: word.length,
                                       autoComplete: "off",
                                   }}
                        />
                    </td>
                    <td width="25%">
                        {flags.map(flag => <span
                            key={"flag_" + f++}
                            className={"flag " + flag}
                        >{"\u00a0"}</span>)}
                    </td>
                </tr>
                </tbody>
            </table> : null;
    }

    renderGuessButton() {
        const word = this.getWord();
        const guess = this.state.guess;
        const candidate = this.getCandidate(guess);

        return <Button color="primary" size="small"
                       disabled={!candidate || word.length !== candidate.length}
                       onClick={this.submitGuess.bind(this)}>
            Guess
        </Button>
    }

    renderGuesses() {
        const completed = this.state.completed;
        const guess = this.state.guess;

        let nums = [];
        let showMore = false;

        if (guess > 7) {
            nums = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

        } else {
            let num = guess + 1;

            while (num > 0) {
                arrays.addTo(nums, num--);
            }

            showMore = guess < 9 && !completed;
        }

        return <div className="guesses">
            {showMore ? <>
                {this.renderGuess(10)}
                <Typography>&#8942;</Typography>
            </> : null}
            {nums.map(num => this.renderGuess(num))}
        </div>
    }

    renderLeaderBoard() {
        const challenge = this.props.challenge;

        return <LeaderBoard
            results={challenge.results}
            scoreLabel="Guesses"
            toScore={toScore}
            sorter={sortByGuesses}
        />
    }

    renderInfo() {
        const completed = this.state.completed;
        const lettersRepeat = this.state.lettersRepeat;
        const knownLetters = this.state.knownLetters;
        const guess = this.state.guess;
        const hint = this.state.hint;
        const word = this.getWord();
        const redacted = hint ? redact(hint, word) : null;

        return !completed ? <>
            <Typography variant="caption" component="div">
                {lettersRepeat ? "letters repeat" : "letters do not repeat"}
            </Typography>
            {knownLetters.length > 0 ?
                <Typography variant="caption" component="div">
                    contains: {strings.commaSeparated(knownLetters)}
                </Typography> : null}
            {redacted ?
                <Typography variant="caption" component="div">
                    {redacted}
                </Typography> : null}
        </> : <Typography variant="caption" component="div">
            guesses: {guess}
        </Typography>;
    }

    renderDefinitions() {
        const definitions = this.getDefinitions();
        let i = 0;

        return definitions.length > 0 ?
            <List>
                {definitions.map(definition =>
                    <ListItem key={"item_" + i++}>
                        <Typography variant="caption">
                            {definition}
                        </Typography>
                    </ListItem>)}
            </List> : null;
    }

    renderUpperActions() {
        const component = this;
        const guess = this.state.guess;
        const hint = this.state.hint;

        const success = () => {
            if (!mobile.isMobile()) {
                component.revealHint(false);

            } else {
                mobile.popRewardedAd();
            }
        };

        return <div className="actions upper">
            {this.renderGuessButton()}
            <Button color="secondary" size="small" className="hint"
                    onClick={() => confirm.confirmOrElse("Watch an ad for a hint about the word?", success, noop)}
                    disabled={guess < 4 || objects.notNullOrUndefined(hint)}
            >
                <AdIcon/>Hint
            </Button>
        </div>
    }

    renderLowerActions() {
        const challenge = this.props.challenge;
        const completed = this.state.completed;
        const saving = this.state.saving;
        const failedToSave = this.state.failedToSave;

        const renderHomeButton = () => {
            return <Button
                color="secondary" variant="text"
                onClick={() => {
                    const callback = failedToSave || (challenge && challenge.mine)
                        ? this.props.onBack
                        : this.props.onDone;

                    if (failedToSave) {
                        confirm.confirm(games.RESULTS_NOT_SAVED, callback);

                    } else {
                        callback();
                    }
                }}
            >
                Home
            </Button>
        };

        const renderSaveButton = () => {
            return <Button
                color="primary" size="small" className="save"
                onClick={this.resendResult.bind(this)}
            >
                <SaveIcon/>Resend results
            </Button>
        };

        const renderRematchButton = () => {
            return <Button
                color="primary" size="small" className="play-again"
                onClick={this.props.onRematch}
            >
                <RematchIcon/>Rematch
            </Button>
        };

        const renderSendChallengeButton = () => {
            return <Button
                color="primary" size="small" className="play-again"
                onClick={this.sendChallenge.bind(this)}
            >
                <SendChallengeIcon/>Send challenge
            </Button>
        };

        const renderPlayAgainButton = () => {
            return <Button
                color="primary" size="small" className="play-again"
                onClick={this.props.onPlayAgain}
            >
                <RematchIcon/>Play again
            </Button>
        };

        return <div className="actions lower">
            {completed ?
                <>
                    {saving ?
                        <CircularProgress size="36px" color="primary"/> : challenge ?
                            <>
                                {renderHomeButton()}
                                {failedToSave ?
                                    renderSaveButton() : challenge.allowRematch ?
                                        renderRematchButton() : null}
                            </> :
                            <>
                                {renderSendChallengeButton()}
                                {renderPlayAgainButton()}
                                <div className="home">
                                    {renderHomeButton()}
                                </div>
                            </>}
                </> :
                <>
                    <Button color="secondary" size="small" variant="outlined" className="pause"
                            onClick={this.props.onQuit}>
                        <PauseIcon/>Pause
                    </Button>
                    <Button color="secondary" size="small" className="help"
                            onClick={() => this.setShowHelp(true)}>
                        <HelpIcon/>How to play
                    </Button>
                </>}
        </div>
    }

    render() {
        const challenge = this.props.challenge;
        const completed = this.state.completed;
        const showHelp = this.state.showHelp;
        const tab = this.state.tab;
        const component = this;
        const leaderBoard = challenge && challenge.results && challenge.results.length > 0;
        const candidates = cacheService.getCachedMoves(gameUtils.getChallengeId(challenge));
        const guesses = candidates.length > 0;
        const tabs = ["Definitions"];

        if (leaderBoard) {
            arrays.addTo(tabs, "Leader Board");
        }

        if (guesses) {
            arrays.addTo(tabs, "Guesses");
        }

        const setTab = (tab) => component.setState({
            tab: tab,
        });

        return <div className="words">
            {this.renderWord()}
            {this.renderInfo()}
            <Divider/>
            {completed ?
                <>
                    <TabBar
                        tabs={tabs}
                        value={tab}
                        onChange={setTab}
                    />
                    <TabPanel value={tab} index={0}>
                        {this.renderDefinitions()}
                    </TabPanel>
                    {tabs.length > 1 ?
                        <TabPanel value={tab} index={1}>
                            {leaderBoard
                                ? this.renderLeaderBoard() : guesses
                                    ? this.renderGuesses() : null}
                        </TabPanel> : null}
                    {tabs.length > 2 ?
                        <TabPanel value={tab} index={2}>
                            {this.renderGuesses()}
                        </TabPanel> : null}
                </> :
                <>
                    {this.renderGuesses()}
                    {this.renderUpperActions()}
                    {leaderBoard ?
                        <>
                            <Divider/>
                            {this.renderLeaderBoard()}
                        </> : null}
                </>}
            <Divider/>
            {this.renderLowerActions()}
            {showHelp ?
                <HelpDialog
                    open={true}
                    onCancel={() => this.setShowHelp(false)}
                /> : null}
        </div>
    }
}

export default withRouter(Words);
